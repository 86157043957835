import Image from 'next/image';

import classNames from 'classnames';

import HomepageSettingsType from '@/types/HomepageSettingsType';

import styles from './Profits.module.scss';

interface Props {
  homepageSettings: HomepageSettingsType;
}

const Profits: React.FC<Props> = ({ homepageSettings }) => {
  return (
    <div className={styles.profits}>
      <Image src="/images/home-profits.jpg" alt="" fill />
      <div className={classNames('container', styles.profitsTexts)}>
        <h3>{homepageSettings.profits_title}</h3>
        <ul className={styles.profitsList}>
          <li className={styles.profitLocations}>
            <span></span>
            <h4>{homepageSettings.profits_item1_title}</h4>
            <p>{homepageSettings.profits_item1_description}</p>
          </li>
          <li className={styles.profitMedia}>
            <span></span>
            <h4>{homepageSettings.profits_item2_title}</h4>
            <p>{homepageSettings.profits_item2_description}</p>
          </li>
          <li className={styles.profitInstallments}>
            <span></span>
            <h4>{homepageSettings.profits_item3_title}</h4>
            <p>{homepageSettings.profits_item3_description}</p>
          </li>
          <li className={styles.profitSafety}>
            <span></span>
            <h4>{homepageSettings.profits_item4_title}</h4>
            <p>{homepageSettings.profits_item4_description}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Profits;
