import classNames from 'classnames';

import styles from './Heading.module.scss';

interface Props {
  title: string;
  subtitle?: string;
  className?: string;
}

const Heading: React.FC<Props> = ({ title, subtitle, className }) => {
  return (
    <div className={classNames(styles.heading, className)}>
      <h2>{title}</h2>
      {subtitle ? <h3>{subtitle}</h3> : null}
    </div>
  );
};
export default Heading;
