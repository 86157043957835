import Image from 'next/image';

import classNames from 'classnames';

import HomepageSettingsType from '@/types/HomepageSettingsType';

import Button from '../utils/Button';

import styles from './Banner.module.scss';

interface Props {
  homepageSettings: HomepageSettingsType;
}

const Banner: React.FC<Props> = ({ homepageSettings }) => {
  return (
    <div className={styles.banner}>
      <Image priority src="/images/home-banner.jpg" alt="" fill />

      <div className="container">
        <div className={styles.bannerTexts}>
          <h1>{homepageSettings.banner_title}</h1>
          <h2>{homepageSettings.banner_subtitle}</h2>
          <div className={styles.bannerActions}>
            <Button href="/">dowiedz się więcej »</Button>
          </div>
        </div>

        <div className={styles.bannerIcons}>
          <div className={classNames(styles.bannerIcon, styles.bannerIconSafety)}>
            W 100% bezpieczna inwestycja
          </div>
          <div className={classNames(styles.bannerIcon, styles.bannerIconInstallments)}>
            Raty 0% bez banku
          </div>
          <div className={classNames(styles.bannerIcon, styles.bannerIconOffer)}>
            Szeroki wybór działek
          </div>
          <div className={classNames(styles.bannerIcon, styles.bannerIconLocations)}>
            Atrakcyjne dostępne lokalizacje
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
