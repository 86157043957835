import Image from 'next/image';

import HomepageSettingsType from '@/types/HomepageSettingsType';

import Button from '../utils/Button';
import Heading from '../utils/Heading';

import styles from './About.module.scss';

interface Props {
  homepageSettings: HomepageSettingsType;
}

const About: React.FC<Props> = ({ homepageSettings }) => {
  return (
    <div className={styles.about}>
      <div className="container">
        <div className={styles.aboutLayout}>
          <div className={styles.aboutImage}>
            <Image src="/images/about-image.png" alt="" fill />
          </div>
          <div className={styles.aboutTexts}>
            <Heading
              title={homepageSettings.about_title}
              subtitle={homepageSettings.about_subtitle}
              className={styles.aboutHeading}
            />

            <div dangerouslySetInnerHTML={{ __html: homepageSettings.about_description }} />

            {homepageSettings.about_button_text ? (
              <Button href="/">{homepageSettings.about_button_text}</Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
