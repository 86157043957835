import type ArticleType from '@/types/ArticleType';

import Article from './Article';

import styles from './ArticlesList.module.scss';

interface Props {
  articles: Array<ArticleType>;
}

const ArticlesList: React.FC<Props> = ({ articles }) => {
  return (
    <div className={styles.articlesList}>
      {articles.map((article) => (
        <Article key={article.id} article={article} />
      ))}
    </div>
  );
};
export default ArticlesList;
