import { Fragment } from 'react';

import Image from 'next/image';

import routes from '@/routes';
import HomepageSettingsType from '@/types/HomepageSettingsType';
import ProjectType from '@/types/ProjectType';

import Heading from '../utils/Heading';

import styles from './OfferMap.module.scss';

interface Props {
  projects: Array<ProjectType>;
  homepageSettings: HomepageSettingsType;
}

const OfferMap: React.FC<Props> = ({ projects, homepageSettings }) => {
  const markerPosition = (project: ProjectType) => {
    const top = 100 - ((project.latitude - 49) / 5.833333) * 100;
    const left = ((project.longitude - 14.116667) / 10.033333) * 100;

    if (top >= 0 && top <= 100 && left >= 0 && left <= 100) {
      return { top: `${top}%`, left: `${left}%` };
    }
  };
  return (
    <div className={styles.offerMap}>
      <div className="container">
        <div className={styles.offerMapLayout}>
          <div className={styles.offerMapTexts}>
            <Heading
              title={homepageSettings.offer_title}
              subtitle={homepageSettings.offer_subtitle}
              className={styles.offerMapHeading}
            />

            <div dangerouslySetInnerHTML={{ __html: homepageSettings.offer_description }} />
          </div>
          <div className={styles.offerMapImage}>
            <Image src="/images/offer-map.png" alt="" fill />
            {projects.map((project) => (
              <Fragment key={project.id}>
                {markerPosition(project) ? (
                  <a
                    key={project.id}
                    href={routes.project(project)}
                    className={styles.offerMapMarker}
                    style={markerPosition(project)}
                    title={project.name}
                  />
                ) : null}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfferMap;
