import Image from 'next/image';
import Link from 'next/link';

import routes from '@/routes';
import type ProjectType from '@/types/ProjectType';

import styles from './Project.module.scss';

interface Props {
  project: ProjectType;
}

const Project: React.FC<Props> = ({ project }) => {
  return (
    <Link href={routes.project(project)} className={styles.project}>
      {project.image_url ? (
        <div className={styles.projectImage}>
          <Image src={project.image_url} alt={project.name} fill />
        </div>
      ) : null}

      <div className={styles.projectTexts}>
        {project.location ? <div className={styles.projectRegion}>{project.location}</div> : null}
        <h3 className={styles.projectTitle}>{project.name}</h3>
        {project.description ? (
          <h4 className={styles.projectDescription}>{project.description}</h4>
        ) : null}
        <div className={styles.projectActions}>
          <span>Sprawdź »</span>
        </div>
      </div>
    </Link>
  );
};
export default Project;
