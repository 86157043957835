import type ProjectType from '@/types/ProjectType';

import Project from './Project';

import styles from './ProjectsList.module.scss';

interface Props {
  projects: Array<ProjectType>;
}

const ProjectsList: React.FC<Props> = ({ projects }) => {
  return (
    <div className={styles.projectsList}>
      {projects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </div>
  );
};
export default ProjectsList;
