import Head from 'next/head';

interface Props {
  title?: string;
  description?: string;
}

const MetaTags: React.FC<Props> = ({ title, description }) => {
  return (
    <Head>
      <title>{title ? `${title} - WlasnaDzialka.eu` : 'WlasnaDzialka.eu'}</title>
      {description ? <meta name="description" content={description} /> : null}
    </Head>
  );
};
export default MetaTags;
