import { GetServerSideProps, NextPage } from 'next';

import About from '@/components/home/About';
import Articles from '@/components/home/Articles';
import Banner from '@/components/home/Banner';
import OfferMap from '@/components/home/OfferMap';
import Profits from '@/components/home/Profits';
import Projects from '@/components/home/Projects';
import Slider from '@/components/home/Slider';
import MetaTags from '@/components/layout/MetaTags';
import articlesService from '@/services/articlesService';
import homepageSettingsService from '@/services/homepageSettingsService';
import projectsService from '@/services/projectsService';
import sliderItemsService from '@/services/sliderItemsService';
import type ArticleType from '@/types/ArticleType';
import HomepageSettingsType from '@/types/HomepageSettingsType';
import type ProjectType from '@/types/ProjectType';
import SliderItemType from '@/types/SliderItemType';

interface Props {
  articles: Array<ArticleType>;
  projects: Array<ProjectType>;
  sliderItems: Array<SliderItemType>;
  homepageSettings: HomepageSettingsType;
}

export const getServerSideProps: GetServerSideProps<Props> = async () => {
  const { articles } = await articlesService.getArticles(1, 6);
  const { projects } = await projectsService.getProjects(1, 30);
  const { slider_items } = await sliderItemsService.getSliderItems(1, 50);
  const { homepage_settings } = await homepageSettingsService.getHomepageSettings();

  return {
    props: { articles, projects, sliderItems: slider_items, homepageSettings: homepage_settings },
  };
};

const Home: NextPage<Props> = ({ articles, projects, sliderItems, homepageSettings }) => {
  return (
    <>
      <MetaTags title="Inwestuj w ziemię" description="Najlepsze oferty działek inwestycyjnych" />
      {homepageSettings.banner_enabled ? <Banner homepageSettings={homepageSettings} /> : null}
      {homepageSettings.projects_enabled ? (
        <Projects projects={projects} homepageSettings={homepageSettings} />
      ) : null}

      {homepageSettings.about_enabled ? <About homepageSettings={homepageSettings} /> : null}

      {homepageSettings.profits_enabled ? <Profits homepageSettings={homepageSettings} /> : null}

      {homepageSettings.offer_enabled ? (
        <OfferMap projects={projects} homepageSettings={homepageSettings} />
      ) : null}

      <Slider sliderItems={sliderItems} />
      {homepageSettings.blog_enabled ? (
        <Articles articles={articles} homepageSettings={homepageSettings} />
      ) : null}
    </>
  );
};

export default Home;
