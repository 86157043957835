import routes from '@/routes';
import type ArticleType from '@/types/ArticleType';
import HomepageSettingsType from '@/types/HomepageSettingsType';

import ArticlesList from '../articles/ArticlesList';
import Button from '../utils/Button';
import Heading from '../utils/Heading';

import styles from './Articles.module.scss';

interface Props {
  articles: Array<ArticleType>;
  homepageSettings: HomepageSettingsType;
}

const Articles: React.FC<Props> = ({ articles, homepageSettings }) => {
  return (
    <div className={styles.articles}>
      <div className="container">
        <Heading title={homepageSettings.blog_title} subtitle={homepageSettings.blog_subtitle} />

        <ArticlesList articles={articles} />

        {homepageSettings.blog_button_text ? (
          <div className={styles.articlesLink}>
            <Button href={routes.articles()}>{homepageSettings.blog_button_text}</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Articles;
