import HomepageSettingsType from '@/types/HomepageSettingsType';
import type ProjectType from '@/types/ProjectType';

import ProjectsList from '../projects/ProjectsList';
import Heading from '../utils/Heading';

import styles from './Projects.module.scss';

interface Props {
  projects: Array<ProjectType>;
  homepageSettings: HomepageSettingsType;
}

const Projects: React.FC<Props> = ({ projects, homepageSettings }) => {
  return (
    <div className={styles.projects}>
      <div className="container">
        <Heading
          title={homepageSettings.projects_title}
          subtitle={homepageSettings.projects_subtitle}
          className={styles.heading}
        />

        <ProjectsList projects={projects} />
      </div>
    </div>
  );
};
export default Projects;
