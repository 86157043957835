import Image from 'next/image';
import Link from 'next/link';

import dayjs from 'dayjs';

import routes from '@/routes';
import type ArticleType from '@/types/ArticleType';

import styles from './Article.module.scss';

interface Props {
  article: ArticleType;
}

const Article: React.FC<Props> = ({ article }) => {
  return (
    <Link href={routes.article(article)} className={styles.article}>
      <div className={styles.articleImage}>
        {article.image_url ? <Image src={article.image_url} alt={article.title} fill /> : null}
      </div>
      <div className={styles.articleTexts}>
        <div className={styles.articleDate}>{dayjs(article.published_at).format('DD.MM.YYYY')}</div>
        <h3 className={styles.articleTitle}>{article.title}</h3>
      </div>
    </Link>
  );
};
export default Article;
