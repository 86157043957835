import Image from 'next/image';

import type SliderItemType from '@/types/SliderItemType';

import Button from '../utils/Button';

import styles from './SliderSlide.module.scss';

interface Props {
  sliderItem: SliderItemType;
  onPrev: () => void;
  onNext: () => void;
}

const SliderSlide: React.FC<Props> = ({ sliderItem, onPrev, onNext }) => {
  return (
    <div className={styles.sliderSlide}>
      <div className={styles.sliderSlideTexts}>
        <div className={styles.sliderSlideDetails}>
          <strong>Polecamy</strong>
          <h2>{sliderItem.title}</h2>
          {sliderItem.description ? <p>{sliderItem.description}</p> : null}
        </div>
        {sliderItem.button_text && sliderItem.button_url ? (
          <div className={styles.sliderSlideActions}>
            <Button href={sliderItem.button_url}>{sliderItem.button_text}</Button>
          </div>
        ) : null}
      </div>
      <div className={styles.sliderSlideImage}>
        {sliderItem.image_url ? (
          <Image src={sliderItem.image_url} alt={sliderItem.title} fill />
        ) : null}
        <div className={styles.sliderSlideNav}>
          <button
            type="button"
            className={styles.sliderSlidePrev}
            onClick={onPrev}
            title="Poprzedni"
          ></button>
          <button
            type="button"
            className={styles.sliderSlideNext}
            onClick={onNext}
            title="Następny"
          ></button>
        </div>
      </div>
    </div>
  );
};
export default SliderSlide;
