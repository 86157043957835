import { useRef } from 'react';

import { Autoplay, EffectCreative, Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type SliderItemType from '@/types/SliderItemType';

import SliderSlide from './SliderSlide';

import styles from './Slider.module.scss';
import 'swiper/css';

interface Props {
  sliderItems: Array<SliderItemType>;
}

const Slider: React.FC<Props> = ({ sliderItems }) => {
  const swiperRef = useRef<SwiperType | null>(null);

  return (
    <div className={styles.slider}>
      <Swiper
        slidesPerView={1}
        loop
        modules={[EffectCreative, Navigation, Autoplay]}
        navigation
        effect="creative"
        speed={1500}
        autoplay={{
          delay: 5000,
        }}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {sliderItems.map((sliderItem) => (
          <SwiperSlide key={sliderItem.id}>
            <SliderSlide
              sliderItem={sliderItem}
              onPrev={() => swiperRef.current?.slidePrev()}
              onNext={() => swiperRef.current?.slideNext()}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default Slider;
